/**
* Threadloom
* https://threadloom.com
*/


// **********************************************
// GravDept:
// Init UI components that are available sitewide.
// **********************************************


// ==============================================
// Notify
// ==============================================

notify.init({
    positionX: 'right',
    positionY: 'bottom'
});
