function runDocumentReady() {
    var tzGuess = moment.tz.guess();

    $('#current-timezone-abbr').html(moment.tz(tzGuess).format('z'));

    var openScoringAt = moment.utc(getTLDataAttribute('tl-open-scoring-at'));
    var lockScoringAt = moment.utc(getTLDataAttribute('tl-lock-scoring-at'));
    var sendAt = moment.utc(getTLDataAttribute('tl-send-at'));
    var now = new Date();

    $('#open_scoring_at').val(openScoringAt.local().format('YYYY-MM-DD HH:mm:ss'));
    if (openScoringAt.local() <= now) {
        $('#open_scoring_at').attr('disabled', 'true');
    }
    $('#lock_scoring_at').val(lockScoringAt.local().format('YYYY-MM-DD HH:mm:ss'));
    $('#send_at').val(sendAt.local().format('YYYY-MM-DD HH:mm:ss'));
    if (sendAt.local() <= now) {
        $('#lock_scoring_at').attr('disabled', 'true');
        $('#send_at').attr('disabled', 'true');
    }
    $('input[name="timezone"]').val(tzGuess);

    var threeHoursMillis = 3 * 60 * 60 * 1000;
    if (sendAt.local() - now < threeHoursMillis) {
        $('#edit-schedule-section').hide();
    }

    refreshScheduleDisplay();

    setInterval(function() {
        refreshScheduleDisplay();
    }, 30000);
}

$(document).ready(function() {
    runDocumentReady();
});

function updateScheduleDataFields(content) {
    $('[data-tl-open-scoring-at').data(
        'tl-open-scoring-at', content['open_scoring_at']);
    $('[data-tl-lock-scoring-at').data(
        'tl-lock-scoring-at', content['lock_scoring_at']);
    $('[data-tl-cron-approve-at').data(
        'tl-cron-approve-at', content['cron_approve_at']);
    $('[data-tl-send-at').data('tl-send-at', content['send_at']);
}

function refreshScheduleDisplay() {
    $('.schedule-format-example').html(moment().add(10, 'm').format('YYYY-MM-DD HH:mm:ss'));

    var openScoringAt = moment.utc(getTLDataAttribute('tl-open-scoring-at'));
    var lockScoringAt = moment.utc(getTLDataAttribute('tl-lock-scoring-at'));
    var cronApproveAt = moment.utc(getTLDataAttribute('tl-cron-approve-at'));
    var sendAt = moment.utc(getTLDataAttribute('tl-send-at'));

    var now = moment.utc();
    var lowercaseCalCopy = {
        sameDay: '[today at] h:mm A',
        nextDay: '[tomorrow at] h:mm A',
        lastDay: '[yesterday at] h:mm A',
        lastWeek: '[last] dddd [at] LT',
        sameElse: 'YYYY-MM-DD [at] h:mm A'
    };

    setScheduleCopy($('#scoring-opens-container'),
        now > openScoringAt ? 'Scoring opened ' : 'Scoring opens ',
        openScoringAt.local().calendar(null, lowercaseCalCopy));
    setScheduleCopy($('#scoring-closes-container'),
        now > lockScoringAt ? 'Scoring closed ' : 'Scoring closes ',
        lockScoringAt.local().calendar(null, lowercaseCalCopy));
    setScheduleCopy($('#auto-approve-container'),
        now > cronApproveAt ? 'Issue auto-approved ' : 'Issue auto-approves ',
        cronApproveAt.local().calendar(null, lowercaseCalCopy));
    setScheduleCopy($('#send-container'), now > sendAt ? 'Issue sent ' : 'Issue sends ',
        sendAt.local().calendar(null, lowercaseCalCopy));
}

function setScheduleCopy(selector, beginCopy, time) {
    selector.html(beginCopy + time + '.');
}
