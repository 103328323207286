/**
* Copyright Threadloom 2021
* https://threadloom.com
*/

var products = {
    init: function() {
        this.addEvents();
    },

    addEvents: function() {
        var _this = this;

        var skipProductsCheckbox = $('#skip-products-checkbox');
        skipProductsCheckbox.on('click', function (e) {
            products.updateCurationState(skipProductsCheckbox.is(':checked'));
        });

        $('.add-product-btn').each(function() {
            $(this).on('click', function (e) {
                var productId = $(this).siblings('.product_id').val();
                products.addCuratedProductRow('row_' + productId);
                products.disableProductButton('row_' + productId);
            });
        });

        $('body').on('click', '#save-products', function (e) {
            products.save();
        });

        products.updateCuratedTextInfo();
    },

    onPageLoad : function () {
        products.fetchCuratorActions();
    },

    fetchCuratorActions: function () {
        $("#loading-curated-products").html = 'Loading...';

        $("#hint-manage-curated-products").hide();
        $("#hint-add-curated-products").hide();
        $("#loading-curated-products").show();

        var url = window.location.pathname.split('/').slice(0, 6).join('/') + '/saved';

        makeAuthorizedAjax(
            url,
            {
              method: 'GET',
              traditional: true,
            }, function (data) {
                $("#loading-curated-products").hide();
                notify.add({title: 'Success', body: 'Product curations loaded.', status: 'success'});
                products.populateCurationTable(data);
            }, function (err) {
                $("#loading-curated-products").html = '<small>Error: Failed to load.</small>';
                notify.add({title: 'Warning', body: 'Failed to load curation state.', status: 'warning'});
            }
        );
    },

    populateCurationTable: function (data) {
        // Reset any products that were previously in the curation table.
        $('#curated-products-table tr[name^="product"]').each(function() {
            products.removeCuratedProduct($(this), /* skipConfirmation */ true);
        });

        data['products'].forEach(function(product) {
            var productRowId = 'row_' + product['canonical_url_hash'];
            products.addCuratedProductRow(productRowId);
            products.disableProductButton(productRowId);
        });

        products.updateCuratedTextInfo();
        products.updateCurationState(data['skip_insertions']);
    },

    updateCurationState: function (skipInsertions) {
        if (skipInsertions === null) {
            // Caller doesn't know the current state. Get it from the checkbox.
            var skipProductsCheckbox = $('#skip-products-checkbox');
            var skipInsertions = skipProductsCheckbox.is(':checked');
        } else {
            // Caller told us the current state. Set the checkbox.
            var skipProductsCheckbox = $('#skip-products-checkbox');
            skipProductsCheckbox.prop('checked', skipInsertions);
        }

        if ( skipInsertions ) {
            $("#curated-products-div").fadeTo(1000, 0.5, function () {
                $("#curated-products-div").css('display', 'none');
            });
            $("#available-products-div").fadeTo(1000, 0.5, function() {
                $("#available-products-div").css('display', 'none');
                $("#insertion-disabled-warning").css('display', '');
            });
        } else {
            $("#curated-products-div").css('opacity', '100').fadeIn();
            $("#available-products-div").css('opacity', '100').fadeIn();
            $("#insertion-disabled-warning").css('display', 'none');
        }
    },

    addCuratedProductRow: function (productRowId) {
        // TODO: Is there a way to do this entirely in jQuery?
        var row = document.getElementById(productRowId); // find row to copy
        if (row === null) {
            console.log('Could not find curated product: ' + productRowId);
            return;
        }
        var table = document.getElementById("curated-products-table"); // find table to append to
        var clone = row.cloneNode(true); // copy children too
        var tr = table.appendChild(clone); // add new row to end of table
        var btn = clone.querySelector(".add-product-btn");
        btn.setAttribute("style", "color:red");
        btn.innerHTML = '<span class="glyphicon glyphicon-remove-circle"></span>';
        btn.addEventListener("click", function () {
            products.removeCuratedProduct($("#curated-products-table #" + clone.id));
        });
        products.updateCuratedTextInfo();
    },

    removeCuratedProduct: function (curatedRow, skipConfirmation) {
        curatedRow.hide();
        if (skipConfirmation === true || confirm('Are you sure want to remove this product?')) {
            curatedRow.remove();
            products.enableProductButton('row_' + curatedRow.find('.product_id').val());
            products.updateCuratedTextInfo();
        } else {
            curatedRow.show();
        }
    },

    updateCuratedTextInfo: function() {
        var rowCount = $('#curated-products-table tr').length;
        var manageProductsHint = $("#hint-manage-curated-products");
        var emptyTableHint = $("#hint-add-curated-products");
        if (rowCount === 0) {
            manageProductsHint.hide();
            emptyTableHint.show();
        } else {
            manageProductsHint.show();
            emptyTableHint.hide();
        }
    },

    enableProductButton: function (productRowId) {
        var productButton = $("#product-candidates-table #" + productRowId).find('.add-product-btn');
        productButton.prop("disabled", false);
        productButton.find(".glyphicon").toggleClass("glyphicon-ok").toggleClass("glyphicon-plus");
    },

    disableProductButton: function (productRowId) {
        var productButton = $("#product-candidates-table #" + productRowId).find('.add-product-btn');
        if (productButton !== null) {
            productButton.prop("disabled", true);
            productButton.find('.glyphicon').toggleClass('glyphicon-plus').toggleClass('glyphicon-ok');
        }
    },

    save: function () {
      var table = document.getElementById("curated-products-table");
      var numProducts = table.rows.length;
      var disable_insertion = $('#skip-products-checkbox').is(':checked');

      if (disable_insertion) {
        var confirmation = 'Are you sure you want to skip product insertions for this issue?';
      } else {
        var confirmation = 'Save ' + numProducts + ' curated products for this issue?';
      }

      if (confirm(confirmation)) {
          var _this = this;
          _this.disabled = true;

          var curatedProducts = [];
          $('#curated-products-table tr[name^="product"]').each(function () {
            var product_id = $(this).find('.product_id').val();
            var post_id = $(this).find('.post_id').val();
            curatedProducts.push({"product_id": product_id, "post_id": post_id});
          });

          payload = {
            skip_product_insertion: disable_insertion,
            products: curatedProducts,
          };

          var url = window.location.pathname.split('/').slice(0, 6).join('/') + '/saved';
          makeAuthorizedAjax(
            url,
            {
              method: 'POST',
              data: JSON.stringify(payload),
              contentType: "application/json",
              traditional: true,
            }, function (data) {
                _this.disabled = false;
                notify.add({title: 'Success', body: 'Product curations saved.', status: 'success'});
                products.populateCurationTable(data);
            }, function (err) {
                _this.disabled = false;
                notify.add({title: 'Failure', body: 'An error occurred while saving.', status: 'danger'});
                products.fetchCuratorActions();
            }
          );
      }
    },
};

products.init();
