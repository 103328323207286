var scorerName = (typeof sessionStorage !== 'undefined'
    && typeof sessionStorage['reviewer'] !== 'undefined')
    ? sessionStorage['reviewer'] : '';

function scoreButtonOnClick(e) {
    var form = $(this).parents('form:first');
    var url = form.attr('action');

    if (form.find('input[type="radio"][name="score"]:checked').length === 0) {
        return;
    }

    makeAuthorizedAjax(url, {
        method: 'POST',
        data: form.serialize()
    }, function (data) {
        var siblingForm = $(form).siblings('form');

        if (typeof data.score.score_id !== 'undefined') {
            siblingForm.find('input[type="hidden"][name="score_id"]').val(data.score.score_id);
            form.find('input[type="hidden"][name="score_id"]').val(data.score.score_id);
        }

        showSuccess('Score saved');

        // Display undo button
        var undoButton = siblingForm.find('.undo-score-button');
        undoButton.removeClass('hide');

        siblingForm.attr('data-tl-scored-by', scorerName);
    });
}

function getTLDataAttribute(name) {
    return $('[data-' + name + ']').data(name);
}

function extendSchedule(name, val, numHours) {
    var issueId = getTLDataAttribute('tl-issue-id');
    var hash = getTLDataAttribute('tl-issue-hash');

    var url = '/' + issueId + '/' + hash + '/schedule/extend';
    makeAuthorizedAjax(url, {
        method: 'POST',
        data: {
            'date': name,
            'date_value': val,
            'extension_num_hours': numHours
        }
    }, function (data) {
        showSuccess('Scoring extension saved.');
        $('#' + name + '-display').html(data[name]);
    }, function (data) {
        showError('Error while saving scoring extension.');
    });
}

function reopenIssue(form) {
    makeAuthorizedAjax($(form).attr('action'), {
        method: 'POST',
        data: {}
    }, function(data) {
        window.location.reload();
    });
}

function registerListeners() {
    $('#extend-1-hour').click(function(e) {
        var lockScoringAt = getTLDataAttribute('tl-lock-scoring-at');
        extendSchedule('lock_scoring_at', lockScoringAt, 1);
    });

    $('#extend-6-hours').click(function(e) {
        var lockScoringAt = getTLDataAttribute('tl-lock-scoring-at');
        extendSchedule('lock_scoring_at', lockScoringAt, 6);
    });

    $('#extend-n-hours').submit(function(e) {
        var _this = $(this);

        var lockScoringAt = getTLDataAttribute('tl-lock-scoring-at');
        var extensionNumHours = _this.find('input[name="n"]').val();

        extendSchedule('lock_scoring_at', lockScoringAt, extensionNumHours);

        e.preventDefault();
    });

    // Delete score submit (undo button click)
    $('.undo-score-button').click(function (e) {
        var undoButton = $(this);

        e.preventDefault();

        var form = $(this).parents('form:first');
        var url = form.attr('action');

        makeAuthorizedAjax(url, {
            method: 'POST',
            data: form.serialize()
        }, function(data) {
            var siblingForm = form.prev('form:first');

            siblingForm.find('input[type="hidden"][name="score_id"]').val('new');
            siblingForm.find('input[type="radio"][name="score"]:checked')[0].checked = false;

            form.find('input[type="hidden"][name="score_id"]').val('');

            undoButton.addClass('hide');
        });
    });

    $('#update-scorer-name').click(function (e) {
        e.preventDefault();

        var currentScorer = $('#current-scorer');

        currentScorer.text('');
        sessionStorage['reviewer'] = '';
        scorerName = '';
        $('#scorer-name').val('');

        $('.previous-button').addClass('hide');
        $('.next-button').addClass('hide');
        $('#all-score-cards').addClass('hide');
        $('#issue-options-card').addClass('hide');
        $('#insert-item-card').addClass('hide');

        currentScorer.parent().parent().addClass('hide');
        $('#get-scorer-name').removeClass('hide');
    });

    // Content submit.
    function submitForm(evt) {
        evt.preventDefault();
        var form = $(evt.currentTarget).parents('form:first');
        var url = form.attr('action');
        var button = $(evt.currentTarget);
        makeAuthorizedAjax(url, {
            method: 'POST',
            data: form.serialize()
        }, function (data) {
            showSuccess('Changes saved');
            var dataResponseValue = button.attr('data-response-value');
            var content = (typeof dataResponseValue !== 'undefined') ? data[dataResponseValue] : data;

            form.addClass('hide');
            $('button.show-form[data-form-id="' + form.attr('id') + '"]').parent('.show-edit-contents-form').removeClass('hide');

            // In case the fields to show aren't a parent of the form.
            if (button.attr('data-show-id')) {
                $('#' + button.attr('data-show-id')).removeClass('hide');
            }

            var val = content[button.attr('data-field')];
            if (button.attr('data-update-id')) {
                $('#' + button.attr('data-update-id')).text(val);
            }
            if (button.attr('data-tl-update') === 'all-schedule-fields') {
                updateScheduleDataFields(content);
                refreshScheduleDisplay();
            }
        }, function (error) {
            var message = 'Error saving changes. Please contact us if this happens again.';

            try {
                var errResponse = JSON.parse(error.responseText);
                message = typeof errResponse['message'] !== 'undefined'
                    ? errResponse['message'] : message;
            } catch (e) {
                // do nothing
            }

            showError(message);
        });
    }
    $('.content-button').click(submitForm);
    $('#validate-and-save-schedule').click(function(evt){
        evt.preventDefault();
        var form = $(this).parents('form:first')[0];
        var schedule = {
            open: {
                element: form.querySelector('#open_scoring_at'),
                wasDisabled: null,
            },
            lock: {
                element: form.querySelector('#lock_scoring_at'),
                wasDisabled: null,
            },
            send: {
                element: form.querySelector('#send_at'),
                wasDisabled: null,
            },
        };
        var scoringTime = new Date(schedule.open.element.value);
        var reviewTime = new Date(schedule.lock.element.value);
        var sendTime = new Date(schedule.send.element.value);

        var oneHour = 1000 * 60 * 60;
        var tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        var formIsValid = true;
        var errorMessage = '';

        if (!schedule.send.element.disabled && sendTime - reviewTime < oneHour * 4) {
            formIsValid = false;
            errorMessage = 'Please schedule send time at least 4 hours after scoring close.';
        }
        if (!schedule.lock.element.disabled && reviewTime - scoringTime < oneHour) {
            formIsValid = false;
            errorMessage = 'Please schedule scoring close at least 1 hour after scoring open.';
        }
        if (!schedule.open.element.disabled && scoringTime < tomorrow) {
            formIsValid = false;
            errorMessage = 'Please schedule scoring open at least 24 hours from now.';
        }

        var errorElement = $('#schedule-error-message');
        if (formIsValid) {
            var inputElementMap = Object.entries(schedule);
            // input must be enabled for the form to submit its value
            for (var i = inputElementMap.length - 1; i >= 0; i -= 1) {
                var nextInput = inputElementMap[i][1];
                nextInput.wasDisabled = nextInput.element.disabled;
                nextInput.element.disabled = false;
            }

            errorElement.html('');
            submitForm(evt);

            // restore any temporarily removed `disabled` attributes
            for (var i = inputElementMap.length - 1; i >= 0; i -= 1) {
                var nextInput = inputElementMap[i][1];
                nextInput.element.disabled = nextInput.wasDisabled;
            }
        } else {
            errorElement.html(errorMessage);
        }
    });

    function printDate(d) {
        return d.getFullYear()
            + '-'
            + (d.getMonth() + 1)
            + '-'
            + d.getDate()
            + ' '
            + d.toTimeString().substr(0, 8);
    }

    $('.edit-position').on('submit', function(evt) {
        evt.preventDefault();

        var $form = $(this);
        var url = $form.attr('action');
        makeAuthorizedAjax(url, {
            method: 'POST',
            data:   $form.serialize()
        }, function(data) {
            window.location.reload();
        }, function(error) {
            showError('Error saving changes. Please contact us if this happens again.');
        });
    });

    $('.update-status-button').click(function (evt) {
        evt.preventDefault();
        var form = $(this).parents('form:first');
        var url = form.attr('action');
        var button = $(this);
        makeAuthorizedAjax(url, {
            method: 'POST',
            data: form.serialize()
        }, function (data) {
            showSuccess('Changes saved');
            form.addClass('hide');
            $('.show-edit-contents-form').removeClass('hide');
            var newStatus = data['issue']['status']['status'];
            var accountIssueStr = button.attr('data-account-issue-str');
            // Update the status in the table.
            var statusElem = $('#status-' + accountIssueStr);
            statusElem.html(newStatus);
            statusElem.attr('class', 'status-' + newStatus);
            // Update the selected status in the form.
            $('.status-input-' + accountIssueStr).removeAttr('checked');
            $('#status-selection-' + accountIssueStr).attr('checked', true);
        }, function (error) {
            showError('Error saving changes. Please contact us if this happens again.');
        });
    });

    // Score submit.
    $('input[type="radio"][name="score"]').click(scoreButtonOnClick);
    $('textarea[name="threadloom_comment"]').change(scoreButtonOnClick);

    // Spam submit.
    $('form[class="mark-as-spam"]').on('submit', function(e) {
        e.preventDefault();
        var $this = $(this);

        makeAuthorizedAjax($this.attr('action'), {
            method: 'POST',
            data: $this.serialize()
        }, function (data) {
            showSuccess('Item marked as spam');
            var $button = $this.find('button');
            $button.attr('disabled', true);
            $button.html('Already marked as spam');
        }, function (error) {
            showError('Error marking the item as spam. Please contact us if this happens again.');
        });
    });

    // Skip button.
    $('.next-button').click(function (evt) {
        evt.preventDefault();
        var currentCard = $('.score-card').not('.hide');
        _showNextCard(currentCard);
    });

    // Previous button.
    $('.previous-button').click(function (evt) {
        evt.preventDefault();
        var currentCard = $('.score-card').not('.hide');
        _showPreviousCard(currentCard);
    });

    $('.get-scorer-name').click(function (e) {
        e.preventDefault();

        var form = $(this).parents('form:first')[0];
        scorerName = form.elements.namedItem('scorer-name').value;

        if (!scorerName) {
            return;
        }
        setUpScorer(scorerName, isReviewPage());
    });

    // Save issue options.
    $('.issue-options-button').click(function (e) {
        e.preventDefault();

        var form = $(this).parents('form:first');
        var url = form.attr('action');
        makeAuthorizedAjax(url, {
            method: 'POST',
            data: form.serialize()
        }, function (data) {
            showSuccess('Changes saved');
        });
    });

    // Show the corresponding form.
    $('.show-form').click(function (e) {
        e.preventDefault();

        var formId = $(this).attr('data-form-id');
        $('#' + formId).removeClass('hide');
        $(this).parents('.show-edit-contents-form:first').addClass('hide');

        if ($(this).attr('data-remove-card-class')) {
            $(this).parents('.card:first').removeClass(
                $(this).attr('data-remove-card-class'));
        }
    });
    // Cancel edit button.
    $('.cancel-edit').click(function (e) {
        e.preventDefault();

        var formId = $(this).attr('data-form-id');
        $('#' + formId).addClass('hide');
        $('button.show-form[data-form-id="' + formId + '"]').parent('.show-edit-contents-form').removeClass('hide');

        if ($(this).attr('data-show-id')) {
            $('#' + $(this).attr('data-show-id')).removeClass('hide');
        }

        if ($(this).attr('data-add-card-class')) {
            $(this).parents('.card:first').addClass($(this).attr('data-add-card-class'));
        }
    });

    $('#show-insert-item').click(function (e) {
        e.preventDefault();
        $('#insert-item').removeClass('hide');
        $('#show-insert-item-description').addClass('hide');
    });

    $('#insert-post-submit').click(function (e) {
        e.preventDefault();

        $('#show-insert-item-after-error').addClass('hide');

        var form = $(this).parents('form:first');
        var url = form.attr('action');
        makeAuthorizedAjax(url, {
            method: 'POST',
            data: form.serialize()
        }, function (data) {
            showSuccess('Changes saved');
            $('#show-insert-item-after-description').removeClass('hide');
        }, function (err) {
            var errText = 'Sorry, something unexpected happened. ' +
                'Try again and contact us if you get this message again.';
            try {
                var response = JSON.parse(err.responseText);
                if (response['error']) {
                    switch (response['error']) {
                        case 'ObjectAlreadyExists':
                            errText = 'That post has already been added.';
                            break;
                        case 'ObjectNotFound':
                            errText = 'Sorry, we couldn\'t find that post. Contact us for help.';
                            break;
                    }
                }
            } catch (e) {
            }
            $('#show-insert-item-after-error').text(errText);
            $('#show-insert-item-after-error').removeClass('hide');
        });
    });

    function approveIssueAPI() {
        return new Promise(function(resolve, reject) {
            var url = window.location.pathname.split('/').slice(0, 5).join('/') + '/status/approve';
            makeAuthorizedAjax(url, {
                method: 'POST',
                data: {}
            }, function () {
                resolve();
            }, function () {
                reject('Failed to approve issue.');
            });
        });
    }

    function sendIssueAPI() {
        return new Promise(function(resolve, reject) {
            var minutesUntilSendTime = 30;
            var sendTimeValue = moment().add(minutesUntilSendTime, 'm').format('YYYY-MM-DD HH:mm:00');
            var sendTimeInput = $('#send_at');
            sendTimeInput.val(sendTimeValue);

            var $form = $('#edit-schedule');
            var url = $form.attr('action');

            // enable all inputs.
            var scheduleInputs = [
                $('#open_scoring_at'),
                $('#lock_scoring_at'),
                $('#send_at'),
            ];
            scheduleInputs.forEach(function(input){
                input.attr('data-state', input.attr('disabled'));
                input.attr('disabled', false);
            });

            makeAuthorizedAjax(url, {
                method: 'POST',
                data: $form.serialize()
            }, function (data) {
                updateScheduleDataFields(data);
                refreshScheduleDisplay();

                resolve();
            }, function (err) {
                var message = 'Error updating schedule. Please contact us if this happens again.';
                try {
                    var errResponse = JSON.parse(err.responseText);
                    message = typeof errResponse['message'] !== 'undefined'
                        ? errResponse['message'] : message;
                } catch (e) {
                    // do nothing
                }

                reject(message);
            });

            // reset all inputs to previous states.
            scheduleInputs.forEach(function(input){
                input.attr('disabled', input.attr('data-state'));
            });
        });
    }

    $('#approve-issue').click(function (e) {
        e.preventDefault();
        var _this = this;
        _this.disabled = true;
        var bumpedDeliveryTime = false;
        approveIssueAPI()
            .then(function() {
                var datetime = Date.now();
                var deliveryDatetime = new Date($('#send_at').val()).valueOf();
                var minDeliveryWait = 30 * 60 * 1000; // 30 minutes as milliseconds
                if (datetime + minDeliveryWait >= deliveryDatetime) {
                    // Approval occurred too close to send time, reschedule to send ASAP
                    bumpedDeliveryTime = true;
                    return sendIssueAPI();
                }
                return Promise.resolve();
            })
            .then(function() {
                var message = 'Issue was approved!';
                if (bumpedDeliveryTime) {
                    message += ' The scheduled send time may vary by up to 30 minutes.';
                }
                showSuccess(message);
            }).catch(function(err) {
                showError(err);
                _this.disabled = false;
            });
    });

    $('#approve-and-send').click(function(e) {
        e.preventDefault();
        var _this = this;
        _this.disabled = true;
        approveIssueAPI()
            .then(sendIssueAPI)
            .then(function() {
                showSuccess('Issue is being prepared for delivery.');
            }).catch(function(err) {
                showError(err);
                _this.disabled = false;
            });
    });

    $('#cancel-issue').click(function (e) {
        e.preventDefault();

        if (confirm('Are you sure you want to cancel this issue?')) {
            var _this = this;
            _this.disabled = true;

            var url = window.location.pathname.split('/').slice(0, 5).join('/') + '/status/cancel';

            makeAuthorizedAjax(url, {
                method: 'POST',
                data: {}
            }, function (data) {
                showSuccess('Issue was cancelled.');
            }, function (err) {
                _this.disabled = false;
                showError('Failed to cancel issue.');
            });
        }
    });

    $('#reopen-issue').click(function(e) {
        e.preventDefault();

        this.disabled = true;
        reopenIssue(this);
    });

    $('#build-email-button').click(function (evt) {
        evt.preventDefault();
        var button = $(this);
        var form = $(this).parents('form:first');
        pubsubButtonClick(button, form, function (data) {
            var emailBuildUrl = data['email_build_url'];
            $('#email-preview-link').attr('href', emailBuildUrl);
        });
    });

    $('.pubsub-button').click(function (evt) {
        evt.preventDefault();
        var button = $(this);
        var form = $(this).parents('form:first');
        pubsubButtonClick(button, form);
    });

    function pubsubButtonClick(button, form, opt_afterSuccessFunc) {
        var prefix = button.attr('data-after-success-prefix');
        var section = $('#' + prefix + '-section');
        section.addClass('hide');
        var url = form.attr('action');
        makeAuthorizedAjax(url, {
            method: 'POST',
            data: form.serialize()
        }, function (data) {
            if (opt_afterSuccessFunc) {
                opt_afterSuccessFunc(data);
            }
            showSuccess('Changes saved');
            var pubsubMessageId = data['message_id'];
            $('#' + prefix + '-pubsub-id').text(pubsubMessageId);
            section.removeClass('hide');
        }, function (error) {
            showError('Error making request.');
        });
    }

    $('#save-all-email-options-button').click(function (evt) {
        evt.preventDefault();
        var form = $(this).parents('form:first');
        var url = form.attr('action');
        var button = $(this);
        makeAuthorizedAjax(url, {
            method: 'POST',
            data: form.serialize()
        }, function (data) {
            showSuccess('Changes saved');
            var curation = data['curation'];
            $('#subject-line').text(curation['subject_line']);
            $('#preview-text').text(curation['preview_text']);
            $('#intro-paragraph').text(curation['intro_paragraph']);
            $('#outro-paragraph').text(curation['outro_paragraph']);
        }, function (error) {
            showError('Error making request.');
        });
    });
}

function registerVerificationListener() {
    $('#send-verification').click(function(e) {
        e.preventDefault();
        sendVerificationEmail();
    });
}

function registerSignOutListener() {
    $('.sign-out').click(function(e) {
        e.preventDefault();
        $('#logged-in').addClass('hide');
        $('#logged-out').removeClass('hide');
        firebase.auth().signOut();
        window.location.reload();
    });
}

function registerFirebaseListeners(opt_prefillEmail) {

    registerVerificationListener();
    registerSignOutListener();

    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        if (!$('#logged-out').hasClass('hide')) {
          // We're already logged-in, don't do repeat work (this call fires more than once).
          return;
        }
        $('#logged-out').addClass('hide');
        var name = user.displayName;

        /* Use the name stored if it exists, otherwise fall back on email. */
        var scoringUsername = name ? name : user.email;

        user.getIdToken(true).then(function(idToken) {
          userIdToken = idToken;

          $('#user').text(scoringUsername);
          $('#logged-in').removeClass('hide');
          $('#non-login-content').html('Loading...');
          $.ajax(window.location.href, {
            cache: false,
            /* Set header for the XMLHttpRequest to get data from the web server
            associated with userIdToken */
            headers: {
              'Authorization': 'Bearer ' + userIdToken
            }
          }).done(function(html) {
              $('#non-login-content').html(html);
              registerVerificationListener();
              runDocumentReady();
              registerListeners();
          }).fail(function(resp) {
              if (resp.status === 401) {
                $('#non-login-content').addClass('hide');

                var template = '[data-js-template="unauthorized-message"]';

                var html = $(template).html();
                html = html.replace(/{{email}}/g, user.email);
                $('#unauthorized').html(html);
                $('#unauthorized').removeClass('hide');
              } else {
                $('#non-login-content').addClass('hide');
                $('#unknown-error-message').removeClass('hide');
              }
              registerSignOutListener();
          });
        });
      } else {
        $('#logged-in').addClass('hide');
        $('#logged-out').removeClass('hide');
        configureFirebaseLoginWidget(window.location.href, opt_prefillEmail);
      }
    });
}

function getUrlVars() {
    var vars = {};

    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
        vars[key] = value;
    });

    return vars;
}

function setUpScorer(scorerName, opt_expandedUi) {
    if (typeof sessionStorage !== 'undefined') {
        sessionStorage['reviewer'] = scorerName;
    }

    var getParams = getUrlVars();

    if (!getParams['show_all']) {
        $('.action-row').removeClass('hide');
        $('.previous-button').removeClass('hide');
        $('.next-button').removeClass('hide');
    } else {
        $('.action-row').addClass('hide');
    }

    $('#all-score-cards').removeClass('hide');
    $('#issue-options-card').removeClass('hide');
    $('#insert-item-card').removeClass('hide');

    $('#get-scorer-name').addClass('hide');

    if (!opt_expandedUi) {
        $('.score-form.card_section').addClass('hide');
        $('.undo-score-form').addClass('hide');

        $('.empty-score-form').removeClass('hide');
        $('.empty-score-form').next('.undo-score-form').removeClass('hide');
    }

    var filledForms = $('[data-tl-scored-by="' + scorerName + '"]');
    filledForms.removeClass('hide');
    filledForms.parent().siblings('.score-card_section').children('.empty-score-form').addClass('hide');

    $('#current-scorer').text(scorerName);
    $('#current-scorer').parent().parent().removeClass('hide');

    if (!opt_expandedUi) {
        // Hide current scorer's "Scorer's username" fields.
        $('.current-scorer-field').addClass('hide');
        filledForms.find('.scorer-field').addClass('hide');
    }

    $('.empty-score-form.score-form').find('input[name="reviewer"]').val(scorerName);
    $('.reviewer-val').val(scorerName);
}

function _showNextCard(currentCard) {
    currentCard.addClass('hide');

    var nextCard = currentCard.next();
    if (nextCard.length !== 0) {
        nextCard.removeClass('hide');
    }

    $(window).scrollTop(0);
    _toggleAvailableActions(nextCard);
}

function _showPreviousCard(currentCard) {
    var previousCard = currentCard.prev();

    currentCard.addClass('hide');
    previousCard.removeClass('hide');
    $(window).scrollTop(0);
    _toggleAvailableActions(previousCard);
}

function _toggleAvailableActions(currentCard) {
    var currentIndex = parseInt(currentCard.attr('data-card-index'));
    var totalCards = parseInt(currentCard.attr('data-total-cards'));

    $('.previous-button')[0].disabled = currentIndex === 0;

    if (currentIndex === totalCards) {
        $('.next-button').addClass('hide');
    } else {
        $('.next-button').removeClass('hide');
    }
}

function showSuccess(message) {
    var successMessage = $("#success-message");
    successMessage.text(message).removeClass('hide');
    setTimeout(function () {
        successMessage.addClass('hide');
    }, 3000);
}

function showError(message) {
    var errorMessage = $('#error-message');
    errorMessage.text(message).removeClass('hide');
    setTimeout(function () {
        errorMessage.addClass('hide');
    }, 3000);
}

// Firebase log-in widget
function configureFirebaseLoginWidget(redirectTo, opt_prefillEmail) {
  var uiConfig = {
    'signInSuccessUrl': redirectTo,
    'signInFlow': 'popup',
    'credentialHelper': firebaseui.auth.CredentialHelper.NONE,
    'signInOptions': [
      {
        provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
        requireDisplayName: true
      }
    ],
    'callbacks': {
      'uiShown': function() {
        setTimeout(function() {
           // Prefill email, change card title.
           if (opt_prefillEmail) {
             $('input[name=email]').val(opt_prefillEmail);
           }
        }, 0);
      }
    },
    // Terms of service url
    'tosUrl': 'https://www.threadloom.com/terms',
  };

  var ui = new firebaseui.auth.AuthUI(firebase.auth());
  ui.start('#firebaseui-auth-container', uiConfig);
}

function sendVerificationEmail() {
  var user = firebase.auth().currentUser;
  var actionCodeSettings = {
    url: window.location.href
  };

  user.sendEmailVerification(actionCodeSettings).then(function() {
      $('#verify-email').html('Thanks, the email has been sent.');
  }).catch(function(error) {
      $('#verify-email').html('Oops, an error occurred. Please try again later.');
  });
}

function makeAuthorizedAjax(url, settings, opt_success, opt_failure) {
    // If logged in as @threadloom admin, userIdToken won't exist. So just don't send it! Backend
    // will know user is @threadloom admin.
    if (typeof userIdToken !== 'undefined') {
        settings['headers'] = settings['headers'] ? settings['headers'] : {};
        settings['headers']['Authorization'] = 'Bearer ' + userIdToken;
    }

    $.ajax(
        url,
        settings
    ).done(function (resp) {
        if (opt_success) {
            opt_success(resp);
        }
    }).fail(function (resp) {
        if (opt_failure) {
            opt_failure(resp);
        }
    });
}
