(function(doc){
    function noop(){}

    function getDomValue(selector) {
        return (doc.querySelector(selector) || {}).value;
    }

    function setPinnedState(itemId, isPinned, optCallback) {
        var currentUrl = window.location.href;
        var destinationUrl = currentUrl.split('/').slice(0, 7).concat('pin').join('/');
        var scoreType = getDomValue('input[name=score_type]');
        var reviewer = getDomValue('input[name=reviewer]');
        var callbackFn = typeof optCallback === 'function' ? optCallback : noop;
        makeAuthorizedAjax(
            destinationUrl,
            {
                method: 'POST',
                data: {
                    item_id: itemId,
                    score_type: scoreType,
                    reviewer: reviewer,
                    pinned: isPinned
                }
            },
            callbackFn.bind(null, false),
            callbackFn.bind(null, true)
        );
    }

    function safelyNotify(notification) {
        if (!doc.querySelector('.notify')) {
            notify.injectHtml();
        }
        notify.add(notification);
    }

    $(doc).ready(function(){
        $('.ptp--tooltip__toggle').click(function(e){
            var $toggle = $(e.currentTarget);
            $toggle.next('.ptp--tooltip__message').toggleClass('hide');
        });

        $('.ptp--checkbox').click(function(e){
            var isPinned = e.currentTarget.checked;
            var itemId = e.currentTarget.dataset.itemId;
            var checkboxElement = e.target;
            setPinnedState(itemId, isPinned, function(err){
                var pinnedText = isPinned ? 'Pinned' : 'Unpinned';
                var notification = {
                    title: pinnedText,
                    body: 'Post was ' + pinnedText.toLowerCase() + ' successfully.',
                    status: 'success'
                };
                if (err) {
                    checkboxElement.checked = !isPinned;
                    notification.title = 'Error';
                    notification.body = 'Your post was not ' + pinnedText.toLowerCase() + '. Please try again&nbsp;later.';
                    notification.status = 'danger';
                }
                safelyNotify(notification);
            });
        });
    });
})(document);
